.slide {
    opacity: 0;
    transition-duration: 1s ease;
}

.canvas {
    font-style: italic;
}

.slide-active {
    opacity: 1;
    transition-duration: 1s;
    color: rgb(26, 34, 194);
}

.header {
    transform: translateY(-2px);
}

.header-active {
    transform: translateY(2px);
    transition-duration: 2s;
}
.dots {
    color: #000;
    font-size: 1.5rem;
}
.dots:active {
    color: rgb(178,12,78);
}
.num{
    text-decoration: none;
    color: #000;
}
.num:hover{
    color: rgba(0,0,0,0.8);
}
.slider {
    width: 800px;
    height: 500px;
}

.imgM {
    width: 50vw;
    height: 100%;
}
.contM {
    width: 50vw;
    margin-left: 150px;
}
.boxM {
    width: 100%;
}

@media screen and (max-width: 850px){
    .slider{
        height: 400px;
        max-width: 600px;;
        margin-bottom: 100px;
    }

}
@media screen and (max-width: 1200px) {
    .imgM {
        min-width: 100vw;
        height: 450px;
        margin-bottom: 50px;
    }
    .contM {
        width: 100vw;
        margin-left: 0px;
        height: 450px;
        margin-bottom: 50px;
    }
    .boxM {
        width: 100%;
        margin-bottom: 50px;
    }
    .arrowsM {
        top: 60%;
    }
    
}
@media screen and (max-width: 768px) {
    .imgM {
        height: 250px;
    }
    .contM {
        height: 250px;
    }
    .arrowsM {
        top: 25%;
    }
}
@media screen and (max-width: 650px){
    .slider{
        height: 350px;
        max-width: 500px;;
        margin-bottom: 100px;
    }

}
@media screen and (max-width: 500px){
    .slider{
        height: 250px;
        
        margin-bottom: 100px;
        margin-left: 0px;
        width: 100vw;
    }

}
@media screen and (max-width: 380px){
    .slider{
        height: 250px;
        max-width: 380px;;
        margin-bottom: 100px;
    }

}