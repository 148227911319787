.contact-sub-header1 {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 25px;
}
.contact1 {
    /* margin-top: 50px; */
    position: relative;
    top: 0;
    
}
.input-box1 {
    width: 500px;
    height: 50px;
    
    border: 2px solid black; /* Set the border color and width */
  border-radius: 5px; /* Optional: Add rounded corners */
  box-sizing: border-box;
}
.input-box2 {
    width: 500px;
    height: 100px;
    border: 2px solid black; /* Set the border color and width */
  border-radius: 5px; /* Optional: Add rounded corners */
  box-sizing: border-box;
}
.contact-input-items1 {
    margin-bottom: 10px;
}
.contact-btn1 {
    width: 200px;
    margin-top: 15px;
}
.sec {
    height: 100%;
    padding-top: 75px;
    padding-bottom: 100px;
}
@media screen and (max-width: 600px) {
    .input-box1 {
        width: 80vw;
    }
    .input-box2 {
        width: 80vw;
    }
    .sec {
        padding-top: 50px;
    }
}