@keyframes getBig {
    from {
        filter: blur(20px);
        opacity: 0;
        transform: scale(2);
        
    }
    to {
        filter: blur(0px);
        opacity: 1;
    transform: scale(1);
    
    }
}
.text-big {
    animation-name: getBig;
    animation-duration: 3s;
}
section {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    
    justify-content: center;
    align-items: center;
    overflow: hidden;
  
}
.gap {
    padding-right: 25px;
}
h5 {
    
font-size: 3em;
    text-transform: uppercase;
    text-align: center;
    display: flex;
}
h5 span {
    
    animation-name: getBig;
    animation-duration: 1.5s;
    opacity: 1;
}

h5 span:nth-child(10n+1) {
    animation-delay: 0s;
   
}
h5 span:nth-child(10n+2) {
    animation-delay: 0.05s;
}
h5 span:nth-child(10n+3) {
    animation-delay: 0.1s;
    
}
h5 span:nth-child(10n+4) {
    animation-delay: 0.15s;
  
}
h5 span:nth-child(10n+5) {
    animation-delay: 0.2s;
    
}
h5 span:nth-child(10n+6) {
    animation-delay: 0.25s;
    
}
h5 span:nth-child(10n+7) {
    animation-delay: 0.3s;
   
}
h5 span:nth-child(10n+8) {
    animation-delay: 0.35s;
    
}
h5 span:nth-child(10n+9) {
    animation-delay: 0.4s;
    
}
h5 span:nth-child(10n+10) {
    animation-delay: 0.45s;

}
h5 span:nth-child(10n+11) {
    animation-delay: 0.5s;
   
}
h5 span:nth-child(10n+12) {
    animation-delay: 0.55s;
  
}
h5 span:nth-child(10n+13) {
    animation-delay: 0.6s;
    
}
h5 span:nth-child(10n+14) {
    animation-delay: 0.65s;

}
h5 span:nth-child(10n+15) {
    animation-delay: 0.7s;
   
}
h5 span:nth-child(10n+16) {
    animation-delay: 0.75s;
 
}
h5 span:nth-child(10n+17) {
    animation-delay: 0.8s;
   
}
h5 span:nth-child(10n+18) {
    animation-delay: 0.85s;
    
}
h5 span:nth-child(10n+19) {
    animation-delay: 0.9s;
  
}
h5 span:nth-child(10n+20) {
    animation-delay: 0.95s;
 
}
h5 span:nth-child(10n+21) {
    animation-delay: 1s;

}


@media screen and (max-width: 768px) {
    h5 {
        font-size: 3em;
    }
    .gap {
        padding-right: 15px;
    }
}
@media screen and (max-width: 500px) {
    h5 {
        font-size: 1.8em;
    }
    .gap {
        padding-right: 10px;
    }
}