.select-container {
  /* margin-top: 25px; */
  position: absolute;
  height: 50px;
  margin-top: 15px;
  width: 100%;
      position: static;
      display: flex;
      justify-content: center;
  /* right: 10%; */
}
select {
  /* Your select box styles here */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
}

/* Style the dropdown arrow icon */
select::-ms-expand {
  display: none; /* Hide the default arrow in Internet Explorer */
}

select::after {
  content: '\25BC'; /* Unicode for a down arrow (adjust this to your preference) */
  position: absolute;
  top: 50%;
  right: 10px; /* Adjust the right positioning as needed */
  transform: translateY(-50%);
  pointer-events: none; /* Prevents interaction with the arrow */
}

/* Style the select when it's focused */
select:focus {
  outline: none;
  border-color: #007bff; /* Change the border color on focus */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Add a subtle shadow on focus */
}

/* Style the options in the dropdown */
select option {
  background-color: #fff;
  color: #333;
}

/* Style the hover effect for options */
select option:hover {
  background-color: #f0f0f0;
}

/* Style the selected option */
select option:checked {
  background-color: #007bff;
  color: #fff;
}
.cards {
    padding: 1rem;
    background: #fff;
    position: relative;
    min-height: 50px;
    width: 100%;
  }
  
  
  
.price__button {
  max-width: 160px;
  margin-top: 10px;
}

  .cards__item__price1 {
    color: green;
    text-align: left;
    font-size: 1.2rem;
    padding-bottom: 5px;
  }
  .cards__container {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 1120px;
    width: 90%;
    margin: 0 auto;
  }
  
  .cards__wrapper {
    position: relative;
    margin: 50px 0 45px;
  }
  
  .cards__items {
    margin-bottom: 24px;
  }
  
  .cards__item {
    display: flex;
    flex: 1;
    margin: 0 1rem;
    border-radius: 10px;
    background: #fff;
  }
  
  .cards__item__link {
    display: flex;
    flex-flow: column;
    width: 100%;
    box-shadow: 0 6px 20px rgba(178, 12, 78, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
  }
  
  .cards__item__pic-wrap {
    position: relative;
    width: 100%;
    height: 250px;
    padding-top: 67%;
    overflow: hidden;
  }
  
  .fade-img {
    animation-name: fade-img;
    animation-duration: 2s;
  }
  
  .cards__item__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    /*bottom: 0;*/
    top: 0;
    margin-top: 20px;
    
    padding: 12px 25px;
    max-width: calc((100%) - 60px);
    font-size: 1.1rem;
    font-weight: 700;
    color: #fff;
    
    background-color: rgba(0, 0, 0, 0.8);
    box-sizing: border-box;
  }
  .pink-bit {
    background: rgb(178, 12, 78);
    padding: 20px;
  }
  
  .cards__item__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
  }
  
  .cards__item__img:hover {
    transform: scale(1.05);
  }
  
  .cards__item__info1 {
    padding: 20px 30px 30px;
  }
  
  .cards__item__text {
    color: #252e48;
    font-size: 28px;
    line-height: 24px;
    color: #252e48;
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;
    font-size: 1.3rem;
    
    text-align: left;
  }
  .cards__item__text2 {
    color: #252e48;
    font-size: 18px;
    margin-top: 6px;
    color: #252e48;
    font-size: 12px;
    margin-top: 6px;
    font-weight: 300;
    text-align: left;
  }
  .cards__item__miles {
    color: #252e48;
    margin-top: 10px;
    font-size: 14px;
    line-height: 24px;
    color: #252e48;
    margin-top: 10px;
    font-size: 14px;
    line-height: 24px;
  }
  .fa-car {
    padding-right: 5px;
    font-size: 1rem;
  }
  
  @media only screen and (min-width: 1200px) {
    .content__blog__container {
      width: 84%;
    }
  }
  
  @media only screen and (min-width: 1024px) {
    .cards__items {
      display: flex;
    }
  }
  
  @media only screen and (max-width: 1024px) {
    .cards__item {
      margin-bottom: 2rem;
    }
  }
  @media screen and (max-width: 1000px) {
    .select-container {
      /* position: relative;
      transform: translateX(50%); */
      margin-top:25px;
      width: 100%;
      position: static;
      display: flex;
      justify-content: center;
    }
  }
  @media screen and (max-width: 550px) {
    .select-container {
      margin-top: 0px;
    }
  }
  @media only screen and (max-width: 500px) {
    .cards__item {
      margin-bottom: 2rem;
    }
    .cards__container {
      display: flex;
      flex-flow: column;
      align-items: center;
      max-width: 1120px;
      width: 100%;
      margin: 0 auto;
    }
    .cards {
      padding: 2rem;
      background: #fff;
      position: relative;
      min-height: 50px;
    }
    .cards__item__text {
      /* font-size: 50px; */
      /* font-style: italic; */
      font-weight: 300;
      /* line-height: 1; */
       /* color: green; */
    }
    h1 {
      font-size: 1.1rem;
    }
    .cards__item__text2 {
      font-size: 1rem;
    }
  }
  