.details-input {
    border: 1px solid #eee;
    padding: 8px 16px;
    border-radius: 5px;
}
.details-text-area {
    height: 250px;
    border: 1px solid #eee;
    padding: 8px 16px;
    border-radius: 5px;
}
.selected-image {
    border: 4px solid rgb(178,12,78);
}