@media screen and (min-width: 1200px) {
    .img {
        width: 50vw;
        height: 100%;
    }
    .img-box {
         /* width: 70vw;  */
    }
    .box {
       
        /* position: absolute; */
        height: 475px;
        left: 0;
         width: 50vw; 
    }
    .cont {
        margin-left: 150px;
        margin-right: 100px;
    }
    
}
@media screen and (max-width: 1200px ) and (min-width: 768px) {
    .img {
        width: 50vw;
        height: 100%;
    }
    .img-box {
         /* width: 70vw;  */
    }
    .box {
       
        /* position: absolute; */
        height: 350px;
        left: 0;
         width: 50vw; 
    }
    .cont {
        margin-left: 285px;
        margin-right: 275px
    }
}
@media screen and (max-width: 768px) and (min-width: 500px) {
    .img {
        height: 100%;
    }
    .box {
        height: 350px;
        margin-bottom: 50px;
    }
    .cont {
        margin-left:0px;
        margin-right: 100px;
    }
}
.arrow-right {
    border-radius: 150px;
    background: rgba(0,0,0,0.5);
}
.arrow-left {
    border-radius: 150px;
    background: rgba(0,0,0,0.5);
}
@media screen and (max-width: 500px) {
    .arrows {
        
  top: 30%;
    }
}